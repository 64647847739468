import { FunctionComponent } from "react";

const Casio: FunctionComponent = () => {
  return (
    <div className="casio hide">
      <p>CASIo</p>
    </div>
  );
};

export default Casio;
